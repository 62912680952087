<template>
    <div>
        <div class="member-right" style="background-color: #fff;">
            <div class="nav-title">
                <div>充值金币</div>
            </div>
            <div class="member-recharge-module">
                <h4>输入充值金额</h4>
                <div class="calc-wrap">
                    <p>输入充值金额</p>
                    <div>
                        <input type="text">
                        元=
                        <input type="text">
                        金币
                    </div>
                </div>
                <div class="sum-wrap">
                    总计：<b>0</b>元，获得<b>0</b>金币 <button class="buy-btn">确认充值</button>
                </div>
            </div>
            <ul class="recharge-rule-tips">
                <li>
                    <h3>充值说明</h3>
                </li>
                <li>
                    1、金币充值xx元起充，1元=x欧币（金币用于下载模型，不参与分成且不可提现）
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        },
        methods: {

        },
        mounted() {

        },
    };
</script>

<style lang="less" scoped>

</style>